<template>
<div class="card check-filter" >
  <div class="card-header">
    <h2 class="mb-0">
      <b-button v-b-toggle="`checkboxFilter-${item.title}`" class="btn btn-link" variant="link">
          {{ $t(`search-result.${item.title}`) }}
      </b-button>
    </h2>
  </div>

  <b-collapse :id="`checkboxFilter-${item.title}`" v-model="isVisible">
    <b-card>

      <b-form-checkbox-group v-model="selected">
        <!-- vacation part  -->
        <ul class="list-group list-group-flush star-rating" v-if="item.kind==='star-rate'">
          <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
            <b-form-checkbox :value="d.label" class="custom-control custom-checkbox">
              <b-form-rating v-model="d.label" inline readonly variant="warning" ></b-form-rating>
            </b-form-checkbox>
          </li>
        </ul>

        <b-input-group v-if="item.kind==='hotel-name'" class="search-hotel-name">
          <b-form-input v-model="searchText" type="text" :placeholder="$t('search-result.search-hotel')"></b-form-input>
        </b-input-group>

        <ul class="list-group list-group-flush name-rating" v-if="item.kind==='hotel-name'">
          <li class="list-group-item" v-for="(d, inx) in hotelNameList" :key="inx">
            <b-form-checkbox :value="d.label" class="custom-control custom-checkbox">
              <span>{{d.label}}</span>
            </b-form-checkbox>
          </li>
        </ul>

        <ul class="list-group list-group-flush hotel-base" v-if="item.kind==='hotel-basis'">
          <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
            <b-form-checkbox :value="d.label" class="custom-control custom-checkbox">
              <span>{{d.text || d.label}}</span>
            </b-form-checkbox>
          </li>
        </ul>

        <!-- organized tour part  -->
        <ul class="list-group list-group-flush" v-if="item.kind==='hotel-subject'">
          <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
            <b-form-checkbox :value="d.value" class="custom-control custom-checkbox">
              <span>{{d.label}}</span>
            </b-form-checkbox>
          </li>
        </ul>
        <ul class="list-group list-group-flush" v-if="item.kind==='hotel-city'">
          <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
            <b-form-checkbox :value="d.value" class="custom-control custom-checkbox">
              <span>{{d.label}}</span>
            </b-form-checkbox>
          </li>
        </ul>
        <ul class="list-group list-group-flush" v-if="item.kind==='travel-duration'">
          <li class="list-group-item" v-for="(d, inx) in item.subItems" :key="inx">
            <b-form-checkbox :value="d.value" class="custom-control custom-checkbox">
              <span>{{d.label}}</span>
            </b-form-checkbox>
          </li>
        </ul>
      </b-form-checkbox-group>
    </b-card>
  </b-collapse>
</div>
</template>

<script>
import { BButton, BCollapse, VBToggle, BCard, BFormCheckboxGroup, BFormCheckbox, BFormRating, BFormInput, BInputGroup,
} from 'bootstrap-vue';

export default {
  name: 'SidebarCollapseItem',
  components: {
    BButton,
    BCollapse,
    BCard,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRating,
    BFormInput,
    BInputGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hotelNameList() {
      return this.getHotelNameList(this.searchText);
    },
  },
  data() {
    return {
      isVisible: true,
      selected: [],
      searchText: '',
    };
  },
  watch: {
    selected() {
      this.$store.commit('SET_FILTER_CONDITION', { key: this.item.kind, value: this.selected });
      this.$emit('change', { kind: this.item.kind, value: this.selected });
    },
  },
  mounted() {
    this.selected = this.$store.getters.GET_FILTER_CONDITION[this.item.kind] || [];
  },
  methods: {
    getHotelNameList(text) {
      const { item } = this;
      if (!item) return [];
      return text === '' ? item.subItems : item.subItems.filter((elm) => elm.label.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },
  },
};
</script>

<style scoped>
  .list-group {
    padding-right: 0;
  }
  .custom-checkbox {
    margin-right: 1.5rem;
    display: block;
  }
  /* .custom-control {
    padding-left: 0;
  } */
  .list-group-flush > .list-group-item {
    border-width: 0 0 2px;
  }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
  }
  .star-rating .b-rating{
    border: none;
    background: transparent;
  }
  .star-rating .list-group-item{
    padding: 0;
  }
  .card-body{
    padding-top: 0;
  }
  .name-rating{
    filter: opacity(0.7);
  }
  /* .search-hotel-name{
    direction: ltr;
  } */
</style>
<style>
  .custom-control-label{
    justify-content: space-between;
    display: flex;
  }
  .check-filter .star-rating .b-rating span.b-rating-star-empty{
    display: none;
  }
  .check-filter .star-rating .custom-control-label::before, .check-filter .star-rating .custom-control-label::after{
    margin: 6px;
  }
  .card.check-filter .card-header .btn{
    direction: ltr;
  }
  .name-rating .custom-control-label{
    direction: ltr;
    text-align: left;
  }
</style>
